import React from "react";

const App: React.FC = () => {
  return (
    <div
      className="h-screen w-screen flex"
      style={{
        backgroundColor: "#131312",
      }}
    ></div>
  );
};

export default App;
