import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

type BonusRequest = {
  id: number;
  client: string;
  clientId: number;
  bonusId: number;
  handledBy: string | null;
  decision: string;
  amount: number;
  status: string;
  ip: string;
  staffId: number | null;
  createdAt: Date;
  updatedAt: Date;
  note: string;
  closedDate: string;
  creationDate: string;
  isCashback: boolean;
  isSunday: boolean;
};

export async function loader({
  params: { username },
}: {
  params: {
    username: string;
  };
}) {
  return username;
}

const Username: React.FC = () => {
  const [bonusId, setBonusId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [queueNumber, setQueueNumber] = useState(null);
  const [bonusRequests, setBonusRequests] = useState<BonusRequest[] | null>(
    null
  );
  const username = useLoaderData();

  useEffect(() => {
    if (!username) {
      return;
    }

    window.location.assign(
      "https://bonusiframe-a3e418a6f3bf.herokuapp.com/bonus/" + username
    );

    const main = async () => {
      const { data: bonusQueue } = await axios.get(
        "https://extrapanel.herokuapp.com/api/getBonusQueue?username=" +
          username
      );
      const { data: cashbackQueue } = await axios.get(
        "https://extrapanel.herokuapp.com/api/getCashbackQueue?username=" +
          username
      );
      const { data: sundayBonusQueue } = await axios.get(
        "https://extrapanel.herokuapp.com/api/getSundayBonusQueue?clientUsername=" +
          username
      );

      const { data } = await axios.get(
        "https://extrapanel.herokuapp.com/api/getClientBonusRequests?clientUsername=" +
          username
      );

      if (data.requests) setBonusRequests(data.requests);

      setFetching(false);

      for (const { clientUsername } of bonusQueue.bonusQueue) {
        if (clientUsername === username) {
          setQueueNumber(bonusQueue.queueNumber);
          setFetching(false);

          return;
        }
      }
      for (const { clientUsername } of cashbackQueue.cashbackQueue) {
        if (clientUsername === username) {
          setQueueNumber(cashbackQueue.queueNumber);
          setFetching(false);

          return;
        }
      }
      for (const { clientUsername } of sundayBonusQueue.bonusQueue) {
        if (clientUsername === username) {
          setQueueNumber(sundayBonusQueue.queueNumber);
          setFetching(false);

          return;
        }
      }
    };

    main();
  }, [username]);
  useEffect(() => {
    if (!queueNumber) return;

    const interval = setInterval(async () => {
      let queue: {
        queueNumber: number;
      };
      if (bonusId === "cashback") {
        const { data: cashbackQueue } = await axios.get(
          "https://extrapanel.herokuapp.com/api/getCashbackQueue?username=" +
            username
        );

        queue = cashbackQueue;
      } else if (bonusId === "sunday") {
        const { data: sundayBonusQueue } = await axios.get(
          "https://extrapanel.herokuapp.com/api/getSundayBonusQueue?username=" +
            username
        );

        queue = sundayBonusQueue;
      } else {
        const { data: bonusQueue } = await axios.get(
          "https://extrapanel.herokuapp.com/api/getBonusQueue?username=" +
            username
        );

        queue = bonusQueue;
      }
      if (!queue) return;

      if (!queue.queueNumber) {
        window.location.reload();

        return;
      }

      setQueueNumber(queue.queueNumber as any);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [queueNumber, bonusId, username]);

  if (!username || fetching)
    return (
      <div
        className="h-screen w-screen flex"
        style={{
          backgroundColor: "#131312",
        }}
      >
        <div role="status" className="flex m-auto h-12 w-12">
          <svg
            aria-hidden="true"
            className="w-full h-full text-gray-300 animate-spin m-auto dark:text-gray-600"
            style={{
              fill: "#ba8e47",
            }}
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      </div>
    );

  return (
    <div
      className="h-screen w-screen flex flex-col relative font-display"
      style={{
        backgroundColor: "#131312",
      }}
    >
      <div className="m-auto">
        <img
          src="https://i.hizliresim.com/Alo02a.png"
          alt="extrabonus"
          className="m-auto"
        />

        <header className="w-full mb-12">
          <h1 className="text-center text-white text-2xl -mt-4 font-bold">
            Bonus Talep Et
          </h1>
          <h2
            className="text-center text-sm"
            style={{
              color: "rgb(186, 142, 71)",
            }}
          >
            {username as string}
          </h2>
        </header>
        {queueNumber ? (
          <div className="text-white text-center m-auto w-40 lg:w-96">
            <h3 className="font-bold text-2xl">
              Sıra Numaranız: {queueNumber}
            </h3>
            <p className="mt-1 text-gray-300 text-sm">
              Ortalama Süre: {queueNumber * 10} saniye
            </p>
          </div>
        ) : (
          <form
            className="flex flex-col w-64 lg:w-96 m-auto"
            onSubmit={async e => {
              e.preventDefault();

              if (!bonusId) return;

              if (bonusRequests && bonusRequests.length !== 0) {
                const firstReq = bonusRequests[0];
                const now = new Date();
                const updatedAt = new Date(firstReq.updatedAt);
                const difference = Math.round(
                  (now.getTime() - updatedAt.getTime()) / 1000
                );
                if (difference < 180) {
                  toast(
                    "Son talep üzerinden 3 dakika geçmeden yeni talep oluşturamazsınız",
                    {
                      theme: "dark",
                      type: "error",
                    }
                  );

                  return;
                }
              }

              setIsLoading(true);

              if (bonusId === "cashback") {
                const { data } = await axios.post(
                  "https://extrapanel.herokuapp.com/api/addCashbackQueue",
                  {
                    clientUsername: username,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (data.ok) {
                  setQueueNumber(data.queueNumber);
                }

                const toasted = toast(
                  data.ok
                    ? `Talebiniz alındı. (${data.queueNumber * 10} Saniye)`
                    : "Talebiniz alınamadı.",
                  {
                    theme: "dark",
                    type: data.ok ? "success" : "error",
                    pauseOnHover: false,
                    pauseOnFocusLoss: false,
                    hideProgressBar: true,
                  }
                );

                if (data.ok) {
                  let seconds = data.queueNumber * 10;
                  setInterval(() => {
                    seconds -= 1;
                    toast.update(toasted, {
                      render: `Talebiniz alındı. (${seconds} Saniye)`,
                    });
                  }, 1000);
                }

                setIsLoading(false);

                return;
              } else if (bonusId === "sunday") {
                const { data } = await axios.post(
                  "https://extrapanel.herokuapp.com/api/addSundayBonusQueue",
                  {
                    clientUsername: username,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (data.ok) {
                  setQueueNumber(data.queueNumber);
                }

                const toasted = toast(
                  data.ok
                    ? `Talebiniz alındı. (${data.queueNumber * 10} Saniye)`
                    : "Talebiniz alınamadı.",
                  {
                    theme: "dark",
                    type: data.ok ? "success" : "error",
                    pauseOnHover: false,
                    pauseOnFocusLoss: false,
                    hideProgressBar: true,
                  }
                );

                if (data.ok) {
                  let seconds = data.queueNumber * 10;
                  setInterval(() => {
                    seconds -= 1;
                    toast.update(toasted, {
                      render: `Talebiniz alındı. (${seconds} Saniye)`,
                    });
                  }, 1000);
                }

                setIsLoading(false);

                return;
              }

              const { data } = await axios.post(
                "https://extrapanel.herokuapp.com/api/addQueue",
                {
                  clientUsername: username,
                  bonusId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              if (data.ok) {
                setQueueNumber(data.queueNumber);
              }

              const toasted = toast(
                data.ok
                  ? `Talebiniz alındı. (${data.queueNumber * 10} Saniye)`
                  : "Talebiniz alınamadı.",
                {
                  theme: "dark",
                  type: data.ok ? "success" : "error",
                  pauseOnHover: false,
                  pauseOnFocusLoss: false,
                  hideProgressBar: true,
                }
              );

              if (data.ok) {
                let seconds = data.queueNumber * 10;
                setInterval(() => {
                  seconds -= 1;
                  toast.update(toasted, {
                    render: `Talebiniz alındı. (${seconds} Saniye)`,
                  });
                }, 1000);
              }

              setIsLoading(false);
            }}
          >
            <select
              defaultValue="bonusType"
              onChange={e => setBonusId(e.target.value)}
              className="mb-4 appearance-none p-2 bg-stone-600 text-white cursor-pointer rounded-md"
              style={{
                backgroundColor: "rgb(50, 50, 50)",
              }}
            >
              <option value="bonusType" disabled>
                Bonus Türü
              </option>
              <option value="155129">
                %10 Çevrim Şartsız Spor Yatırım Bonusu
              </option>
              <option value="155128">
                %10 Çevrim Şartsız Casino Yatırım Bonusu
              </option>
              <option value="cashback">%10 Anlık Nakit İade</option>
              <option value="sunday">Extra Derbi %10 FreeBet</option>
            </select>
            <button
              className="rounded-md py-0 px-1 text-lg w-32 h-10 m-auto transition-opacity duration-300 ease-in font-medium text-black uppercase"
              style={{
                background: "#ba8e47",
                opacity: isLoading ? 0.5 : 1,
              }}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <div role="status" className="flex w-full h-full">
                  <svg
                    aria-hidden="true"
                    className="w-full h-full text-gray-200 animate-spin m-auto dark:text-gray-600 fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : (
                "Talep Et"
              )}
            </button>
          </form>
        )}
        <div className="text-gray-400 h-64 mt-8 overflow-auto lg:w-700">
          <p className="text-center text-gray-300 font-bold mb-2">
            Önceki Talepler
          </p>
          {bonusRequests?.length !== 0 && (
            <div
              style={{
                backgroundColor: "rgb(32, 32, 32)",
              }}
              className="rounded-md px-2 py-1"
            >
              {bonusRequests?.map(req => (
                <div
                  key={req.id}
                  className="flex h-16 last:mb-0 px-4 py-16 lg:py-12 lg:px-0 text-xs lg:text-sm justify-between border-b border-gray-500 last:border-none items-center"
                >
                  <p className="w-1/4 pl-2 text-start">
                    {req.closedDate || req.creationDate}
                  </p>
                  <p
                    className="text-center font-bold w-3/4 text-xs"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    (
                    {req.isSunday
                      ? "Extra Derbi %10 FreeBet"
                      : !req.isCashback
                      ? req.bonusId === 155129
                        ? "%10 Çevrim Şartsız Spor Yatırım Bonusu"
                        : "%10 Çevrim Şartsız Casino Yatırım Bonusu"
                      : "%10 Anlık Nakit İade"}
                    ) {req.note || req.status}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Username;
